.conversejs {
    .btn {
        font-weight: normal;
        color: var(--button-text-color);
        &:hover {
            color: var(--button-hover-text-color);
        }
        &.fa {
            color: var(--button-text-color) !important;
        }
        i {
            &.fa, &.far, &.fas {
                color: var(--button-text-color);
                margin-right: 0.5em;
                &.only-icon {
                    margin-right: 0;
                }
            }
        }
        converse-icon {
            display: inline-block;
            margin-right: 0;
        }
    }

    .btn-primary {
        background-color: var(--primary-color) !important;
        border-color: transparent !important;
        &:focus, &:hover, &:active {
            color: var(--button-text-color);
            background-color: var(--primary-color-dark) !important;
            border-color: transparent !important;
        }
    }

    .btn--transparent {
        background: transparent;
        border: none;
    }

    .btn-circle {
        width: 30px;
        height: 30px;
        text-align: center;
        padding: 0.5em 0;
        font-size: var(--font-size-small);
        line-height: 1.428571429;
        border-radius: 50%;
    }

    .btn-info, .badge-info {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
        &:hover {
            background-color: var(--primary-color-dark);
            border-color: var(--primary-color-dark);
        }
    }

    .button-cancel,
    .btn-secondary, .badge-secondary {
        color: var(--button-text-color);
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
        &:hover {
            background-color: var(--secondary-color-dark);
            border-color: var(--secondary-color-dark);
        }
    }

    .btn-warning {
        color: var(--button-text-color);
        background-color: var(--warning-color);
        border-color: var(--warning-color);
        &:hover {
            color: var(--button-text-color);
            background-color: var(--warning-color-dark);
            border-color: var(--warning-color-dark)
        }
    }

    .btn-danger {
        color: var(--button-text-color);
        background-color: var(--danger-color);
        border-color: var(--danger-color) !important;
        &:hover {
            background-color: var(--danger-color-dark);
            border-color: var(--danger-color-dark);
        }
    }
}
