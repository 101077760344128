@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "shared/styles/_mixins.scss";

converse-chat-message-body {
    overflow-y: hidden; // Hide zalgo text that overflows vertically

    audio {
        display: block;
        max-width: 95%;
    }

    video {
        display: block;
        max-height: 25em;
        @include media-breakpoint-down(sm) {
            max-width: 95%;
        }
        @include media-breakpoint-up(md) {
            max-width: 70%;
        }
        @include media-breakpoint-up(lg) {
            max-width: 50%;
        }
        @include media-breakpoint-up(xl) {
            max-width: 40%;
        }
    }
}

.chat-msg--me { 
    converse-chat-message-body {
        margin: 0 -0.4em 0 1.5em;
        background-color: #e6e6e6;
        border: 1px solid #e6e6e6;
        color: #000000;
    }

    .menu-buttons-wrapper {
        margin: 0.5em -0.4em 0 0;
    }
}
.chat-msg--them {
    converse-chat-message-body {
        margin: 0 1.5em 0 -0.4em;
        background-color: #3aa569;
        border: 1px solid #3aa569;
        color: #ffffff;
    }

    .menu-buttons-wrapper {
        margin: 0.5em 0 0 -0.4em;
    }
}

.converse-overlayed {
    converse-chat-message-body {
        audio {
            display: block;
            max-width: 100%;
        }
        video {
            display: block;
            max-width: 100%;
        }
    }
}
