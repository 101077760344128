.conversejs {
    converse-chats {
        &.converse-overlayed {
            converse-minimized-chats {
                order: 100;
            }

            #minimized-chats {
                min-width: var(--minimized-chats-width);
                color: var(--chat-head-text-color);

                a.restore-chat {
                    cursor: pointer;
                    padding: 1px 0 1px 5px;
                    color: var(--chat-head-text-color);
                    line-height: 15px;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    &:hover {
                        text-decoration: none;
                    }
                }

                a.restore-chat:visited {
                    color: var(--chat-head-text-color);
                }

                .minimized-chats-flyout {
                    flex-direction: column-reverse;
                    bottom: 45px;
                    width: var(--minimized-chats-width);

                    .chat-head {
                        flex-shrink: 1;
                        min-height: 0;
                        padding: 0.3em;
                        border-radius: var(--chatbox-border-radius);
                        height: 35px;
                        margin-bottom: 0.2em;
                        width: 100%;
                        flex-wrap: nowrap;
                        background-color: var(--chat-head-color);
                    }
                    .chat-head-chatroom {
                        background-color: var(--chatroom-head-bg-color);
                        a.restore-chat {
                            color: var(--chatroom-head-color);
                        }
                    }
                    .chat-head-headline {
                        background-color: var(--headlines-head-bg-color);
                        a.restore-chat {
                            color: var(--headlines-head-text-color);
                        }
                    }

                    &.minimized {
                        height: auto;
                    }
                }
            }
        }
    }
}
