$mobile_landscape_height: 450px !default;
$mobile_portrait_length: 480px !default;

.conversejs, .conversejs-bg, #conversejs-bg, body.converse-fullscreen {
    --avatar-border-radius: 10%;
    --message-avatar-width: 36px;
    --message-avatar-height: 36px;

    --controlbox-width: 250px;
    --chatroom-width: 500px;

    --send-button-height: 27px;
    --send-button-margin: 3px;

    --inline-action-margin: 0.75em;

    --button-border-radius: 5px;
    --chatbox-border-radius: 4px;

    --normal-font: "Helvetica", "Arial", sans-serif;
    --heading-font: 'Muli', normal;
    --branding-font: 'Baumans', cursive;

    --font-size-tiny: 10px;
    --font-size-small: 12px;
    --font-size: 14px;
    --font-size-large: 16px;
    --font-size-huge: 20px;
    --message-font-size: var(--font-size);

    --line-height-small: 14px;
    --line-height: 16px;
    --line-height-large: 20px;
    --line-height-huge: 27px;

    --embedded-emoji-picker-height: 300px;

    --minimized-chats-width: 130px;
    --mobile-chat-width: 100%;
    --mobile-chat-height: 400px;

    --overlayed-chat-gutter: 1em;
    --overlayed-chat-head-height: 55px;
    --overlayed-chat-height: 450px;
    --overlayed-chat-width: 300px;
    --overlayed-chatbox-hover-height: 1em;
    --overlayed-emoji-picker-height: 200px;
    --overlayed-max-chat-textarea-height: 200px;

    --list-toggle-font-weight: normal;
}

.conversejs {
    &.converse-overlayed {
        --message-font-size: var(--font-size-small);
    }
}
