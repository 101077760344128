.conversejs {
    converse-muc.chatroom {
        .chat-head-chatroom {

            converse-controlbox-navback {
                .fa-arrow-left {
                    svg {
                        fill: var(--chatroom-head-color);
                    }
                }
            }

            color: var(--chatroom-head-color);
            background-color: var(--chatroom-head-bg-color);
            border-bottom: var(--chatroom-head-border-bottom);

            .chat-head__desc {
                color: var(--chatroom-head-color);
                display: var(--chatroom-head-description-display);
                a {
                    color: var(--chatroom-head-description-link-color);
                }
                &:hover {
                    button {
                        display: inline-block;
                    }
                }
            }

            .chatbox-title {
                .btn--transparent {
                    i {
                        color: var(--chatroom-head-color);
                    }
                }
                .chatbox-title__text--bookmarked {
                    margin-left: 0.5em;
                }
            }

            .chatbox-title__buttons {
                background-color: var(--chatroom-head-bg-color);
            }

            a, a:visited, a:hover, a:not([href]):not([tabindex]) {
                &.chatbox-btn {
                    &.fa {
                        color: var(--chatroom-head-color);
                        &.button-on:before {
                            color: var(--chatroom-head-fg-color);
                        }
                    }
                }
            }

            converse-dropdown {
                .dropdown-menu {
                    converse-icon {
                        svg {
                            fill: var(--chatroom-color);
                        }
                    }
                }
            }

            .chatbox-btn {
                converse-icon {
                    svg {
                        fill: var(--chatroom-head-fg-color);
                    }
                }
            }
            .chatbox-title__text {
                color: var(--chatroom-head-color);
                display: var(--heading-display);
                font-weight: var(--chatroom-head-title-font-weight);
                margin: auto 0;
                padding-right: var(--chatroom-head-title-padding-right);
                white-space: nowrap;
                .chatroom-jid {
                    font-size: var(--font-size-small);
                }
            }
        }
    }
}
